body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.CardContainer {
  margin: 20px;
  border: 2px solid black;
}

.ProfilePhoto {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.Container {
  padding: 0px !important;
  margin: 0px !important;
}

@media (max-width: 1000px) {
  .Container {
    display: flex;
    flex-wrap: wrap;
  }
} 


svg {
  margin-top: 5px;
  margin-right: 10px;
}
