.Sidepanel {
  margin: 10px;
  margin-top: 25px;
}

.ProfileLink {
  color: black;
  text-decoration: none;
}

.HomepageIcons {
  position: absolute;
  top: 8px;
  right: 30px;
}
